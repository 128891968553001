import React from 'react';
import { graphql } from 'gatsby';
import { SEO, Section, Container, Banner, FurnitureGallery } from 'components';
import { useWindowSize } from 'hooks';
import { H2 } from '../styles/mixins';

export const query = graphql`
  query FurniturePageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    bannerImage: file(relativePath: { eq: "assets/images/furniture-banner.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 90, layout: FULL_WIDTH)
      }
    }
    furniture: allSanityFurniture(sort: { fields: title }) {
      edges {
        node {
          title
          shortTitle
          _rawBody
          id
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;

const FurniturePage = (props) => {
  const { data } = props;
  const { windowWidth } = useWindowSize();
  const site = (data || {}).site;
  const { bannerImage } = data;
  const { edges: furniture } = data.furniture;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }
  const banner = {
    text: 'Let us guide you through, step by step, from consultation to installation.',
    img: bannerImage
  };

  return (
    <main>
      <SEO
        title="Bespoke Furniture"
        description="Bespoke furniture to suit your home. Living room, office, kitchen and bedroom furniture."
        keywords={site.keywords}
      />
      <Banner banner={banner} />
      <Container>
        {furniture.map(({ node }, i) => (
          <Section
            key={node.id}
            img={node.mainImage}
            title={node.title}
            portableText={node._rawBody}
            flipped={i % 2 == 0}
          />
        ))}
      </Container>
      <H2 style={{ textAlign: 'center' }}>Furniture Gallery</H2>
      <FurnitureGallery />
    </main>
  );
};

export default FurniturePage;
